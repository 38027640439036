<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'employees' }">Users</router-link>
        </li>
        <li class="breadcrumb-item active">New</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">New Employee</h2>
        </div>
        <div class="col-md-1 text-right">
          <router-link
            :to="{ name: 'users' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
          <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h2>Add New User</h2>
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label">Employee</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.tempName.$model"
                    :validator="$v.form.tempName"
                    @change="setUserData"
                    :options="employees"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.email.$error == true"
                    >Please select employee </span
                  >
                </div>
                <div class="mb-3">
                  <label for="firstName" class="form-label">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="$v.form.email.$model"
                    :validator="$v.form.email" :readonly="this.form.email != null ? true : false"
                  />
                  <span
                    class="error "
                    v-if="$v.form.email.$error == true"
                    >Email is required</span
                  >
                </div>

                <div class="mb-3">
                  <label class="form-label">Account type</label>
                  <input
                    type="radio"
                    class=""
                    v-model="$v.form.acc_type.$model"
                    :validator="$v.form.acc_type"
                    value="2"
                    id="admin"
                  /><label for="admin">Admin</label>
                  <input
                    type="radio"
                    class=""
                    v-model="$v.form.acc_type.$model"
                    :validator="$v.form.acc_type"
                    value="1"
                    id="employee"
                  /><label for="employee">Employee</label>
                  <span
                    class="error "
                    v-if="$v.form.acc_type.$error == true"
                    >Please select Account Type</span
                  >
                </div>

                <div class="mb-3">
                  <label class="form-label">Role</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.role_id.$model"
                    :validator="$v.form.role_id"
                    :options="roles"
                  ></b-form-select>
                  
                  <span
                    class="error "
                    v-if="$v.form.role_id.$error == true"
                    >Please select a role</span
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label">Status</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.status.$model"
                    :validator="$v.form.status"
                    :options="status"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.status.$error == true"
                    >Status is required</span
                  >
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Password</label>
                      <input
                        type="password"
                        class="form-control"
                        v-model="$v.form.password.$model"
                        :validator="$v.form.password"
                      />
                      <span
                        class="error "
                        v-if="$v.form.password.$error == true"
                        >Please enter a password</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Confirm Password</label>
                      <input
                        type="password"
                        class="form-control"
                        v-model="$v.form.password_confirmation.$model"
                        :validator="$v.form.password_confirmation"
                      />
                      <span class="error" v-if="$v.form.password && !$v.form.password_confirmation.sameAsPassword">Password and Confirm Password should match</span>
                    </div>
                  </div>
                </div>
                <p class="error" v-if="error != ''">{{this.error}}</p>
                <div class="text-right">
                    <button type="submit" class="btn btn-outline-primary space" >Register</button>
                    <router-link :to="{name: 'users'}" class="btn btn-outline-primary" >Cancel</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required, sameAs } from "vuelidate/lib/validators";
import axios from 'axios';
//require('../../axios.js');

export default {
  props: ["id"],
  data() {
    return {
      form: {
        name: null,
        tempName: null,
        email: null,
        acc_type: null,
        role_id: null,
        status: null,
        ref: null,
        password: null,
        password_confirmation: null,
      },
      status: [
        {
          value: "1",
          text: "Enabled",
        },
        {
          value: "0",
          text: "Disabled",
        },
      ],
      error: ''
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      tempName: {
        required,
      },
      email: {
        required,
      },
      acc_type: {
        required,
      },
      role_id: {
        required,
      },
      status: {
        required,
      },
      password: {
        required,
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    },
  },
  created() {
    this.$store.dispatch('switchLoader',true);
      this.$store.dispatch("loadUsers");
  },
  mounted() {
    
    console.log('users ', this.employees);
  },
  computed: {
    roles() {
      return this.$store.state.users.roles.map((role) => {
          return {
              value: role.id,
              text: role.role_name
          }
      });
    },
    employees() {
      return this.$store.state.users.employees.map((employee) => {
        return {
          value: employee.id,
          text: employee.lastname +', '+employee.firstname,
        };
      });
    },
  },
  methods: {
    setUserData(){
        console.log(this.form.tempName);
        let empDetail = this.$store.state.users.employees.filter((emp) => {  return emp.id == this.form.tempName})[0];
        this.form.email = empDetail.emailaddress;
        this.form.ref = this.form.tempName;
        this.$v.form.name.$model = empDetail.lastname+', '+empDetail.firstname;
        console.log('name',this.$v.form.name);
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      axios
        .post("users/register", this.form)
        .catch(({ response }) => {
          if(response.status == 409){
            this.error = response.data.error;
          }
          
        })
        .then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "users" });
          } else {
            this.flashMessage.error({
              message: data.data.error,
              time: 3000,
            });
          }
        });
    },
    onChangeFileUpload() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>